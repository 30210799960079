import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { Header } from "./components/Header";
import { HomePage } from "./home/Home";
import { MartingalePost } from './posts/MartingalePost';
import { BankManagementPost } from './posts/BankManagementPost';
import { BestStrategiesPost } from './posts/BestStrategiesPost';
import { useLayoutEffect } from 'react';

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children
}

function App() {

  return (
    <div className="App">
      <Header />
      <BrowserRouter>
        <Wrapper>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/post/1" element={<MartingalePost />} />
            <Route path="/post/2" element={<BankManagementPost />} />
            <Route path="/post/3" element={<BestStrategiesPost />} />
          </Routes>
        </Wrapper>
      </BrowserRouter>
    </div>
  );
}

export default App;
