import logo from './../assets/images/logotipo.jpg'
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

export const Header = () => {
    return (
        <Navbar expand="lg" className="bg-white shadow-sm">
            <Container>
                <Navbar.Brand className='d-flex align-items-center gap-3' href="/">
                    <img
                        src={logo}
                        className="d-inline-block align-top"
                        alt="React Bootstrap logo" />
                    <h3 className='h6 p-0 m-0'>Roleta Análises e Estratégias</h3>
                </Navbar.Brand>
            </Container>
        </Navbar>
    )
}