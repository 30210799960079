import { Link } from "react-router-dom";

const posts = [
    {
        title: 'Estratégia de Martingale na Roleta: Como Funciona e Dicas Essenciais',
        image: '/images/roulette-post-image.jpg',
        link: '/post/1'
    },
    {
        title: 'Gestão de Banca: A Chave para Jogar Roleta de Forma Segura',
        image: '/images/roulette-post-image2.jpg',
        link: '/post/2'
    },
    {
        title: 'As Melhores Estratégias de Roleta: Quais Realmente Funcionam?',
        image: '/images/roulette-post-image3.jpg',
        link: '/post/3'
    }
]

export const PostsSection = () => {
    return (
        <section className="w-100 d-flex flex-column flex-lg-row fle align-items-center justify-content-center gap-3">
            {posts.map(post => {
                return (
                    <div className="rounded" key={post.link} style={{ width: '100%', maxWidth: 400 }}>
                        <Link to={post.link} className="text-decoration-none text-dark fw-light text-start">
                            <div>
                                <img className="rounded-top" src={post.image} alt="" width={'100%'} height={240} />
                                <div className="p-3 border rounded-bottom">
                                    <h6>{post.title}</h6>
                                </div>
                            </div>
                        </Link>
                    </div>
                )
            })}
        </section>
    )
}